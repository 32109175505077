<template>
  <div class="inner-container">
    <div class="section-title bg-info"><span>Государственные органы</span></div>
    <b-row>
      <b-col>
        <b-nav vertical>
          <b-nav-text><b>Высшие органы гос.власти</b>            
          </b-nav-text>
          <b-nav-text  class="nav-xs">
              <a
                href="https://www.akorda.kz/"
                target="_blank"
                rel="noopener noreferrer"
                >Официальный сайт Президента Республики Казахстан</a
              >
            </b-nav-text>
            <b-nav-text class="nav-xs">
              <a
                href="https://primeminister.kz/"
                target="_blank"
                rel="noopener noreferrer"
                >Официальный информационный ресурс Премьер-Министра Республики
                Казахстан</a
              >
            </b-nav-text>
        </b-nav>
      </b-col>
      <b-col>
        <b-nav vertical>
          <b-nav-text ><b>Министерства</b>            
          </b-nav-text>
          <template v-for="ministry in ministries">
              <b-nav-text :key="ministry.url" class="nav-xs">
                <span
                  v-if="ministry.children.length > 0"
                  @click="ministry.showChildren = !ministry.showChildren"
                  :key="ministry.url"
                >
                  <!-- Оптимизировать смену иконки -->
                  <b-icon
                    v-if="ministry.showChildren"
                    icon="caret-up"
                    font-scale="1"
                  ></b-icon>
                  <b-icon v-else icon="caret-right" font-scale="1"></b-icon>
                </span>
                <a
                  :href="ministry.url"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ ministry.title }}</a
                >

                <template v-if="ministry.showChildren">
                  <b-nav-item
                    v-for="comitet in ministry.children"
                    :key="comitet.url"
                    :href="comitet.url"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ comitet.title }}
                  </b-nav-item>
                </template>
              </b-nav-text>
            </template>
        </b-nav>
      </b-col>
      <b-col>
        <b-nav vertical>
          <b-nav-text
            ><b>Акиматы</b>           
          </b-nav-text>
           <b-nav-text v-for="link in akimats" :key="link.url" class="nav-xs">
              <a :href="link.url" target="_blank" rel="noopener noreferrer">{{
                link.title
              }}</a>
            </b-nav-text>
        </b-nav></b-col
      >
      <b-col
        ><b-nav vertical>
          <b-nav-text
            ><b>Другие</b>            
          </b-nav-text>
          <template v-for="link in others" >
              <b-nav-text :key="link.url" class="nav-xs">
                <span
                  v-if="link.children"
                  @click="link.showChildren = !link.showChildren"
                  :key="link.url"
                >
                  <!-- Оптимизировать смену иконки -->
                  <b-icon
                    v-if="link.showChildren"
                    icon="caret-up"
                    font-scale="1"
                  ></b-icon>
                  <b-icon v-else icon="caret-right" font-scale="1"></b-icon>
                </span>
                <a :href="link.url" target="_blank" rel="noopener noreferrer">{{
                  link.title
                }}</a>

                <template v-if="link.showChildren">
                  <b-nav-item
                    v-for="sublink in link.children"
                    :key="sublink.url"
                    :href="sublink.url"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ sublink.title }}
                  </b-nav-item>
                </template>
              </b-nav-text>
            </template>
        </b-nav></b-col
      >
    </b-row>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
  name: "links-catalog",
  data() {
    return {
      ministries: [
        {
          title: "Министерство внутренних дел Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/qriim?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Национальная гвардия",
              url: "https://www.gov.kz/memleket/entities/kvv?lang=ru",
            },
            {
              title: "Комитет административной полиции",
              url: "https://www.gov.kz/memleket/entities/police?lang=ru",
            },
            {
              title: "Комитет миграционной службы",
              url: "https://www.gov.kz/memleket/entities/migration?lang=ru",
            },
            {
              title: "Комитет уголовно-исполнительной системы",
              url: "https://www.gov.kz/memleket/entities/ps?lang=ru",
            },
          ],
        },
        {
          title: "Министерство здравоохранения Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/dsm?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет медицинского и фармацевтического контроля",
              url: "https://www.gov.kz/memleket/entities/kmfk?lang=ru",
            },
            {
              title: "Комитет санитарно-эпидемиологического контроля",
              url: "https://www.gov.kz/memleket/entities/kkkbtu?lang=ru",
            },
          ],
        },
        {
          title:
            "Министерство индустрии и инфраструктурного развития Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/miid?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет государственного оборонного заказа  ",
              url: "https://www.gov.kz/memleket/entities/kgoz?lang=ru",
            },
            {
              title:
                "Комитет по делам строительства и жилищно-коммунального хозяйства  ",
              url: "https://www.gov.kz/memleket/entities/kds?lang=ru",
            },
            {
              title: "Комитет транспорта  ",
              url: "https://www.gov.kz/memleket/entities/transport?lang=ru",
            },
            {
              title: "Комитет индустриального развития  ",
              url: "https://www.gov.kz/memleket/entities/comprom?lang=ru",
            },
            {
              title: "Комитет гражданской авиации  ",
              url: "https://www.gov.kz/memleket/entities/aviation?lang=ru",
            },
            {
              title: "Комитет автомобильных дорог  ",
              url: "https://www.gov.kz/memleket/entities/roads?lang=ru",
            },
          ],
        },
        {
          title: "Министерство иностранных дел Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/mfa?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет по инвестициям  ",
              url: "https://www.gov.kz/memleket/entities/invest?lang=ru",
            },
            {
              title: "Комитет международной информации  ",
              url: "https://www.gov.kz/memleket/entities/intinf?lang=ru",
            },
          ],
        },
        {
          title:
            "Министерство информации и общественного развития Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/qogam?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет по развитию межэтнических отношений",
              url: "https://www.gov.kz/memleket/entities/etnic?lang=ru",
            },
            {
              title: "Комитет информации",
              url: "https://www.gov.kz/memleket/entities/inf?lang=ru",
            },
            {
              title: "Комитет по делам молодежи и семьи",
              url: "https://www.gov.kz/memleket/entities/fam?lang=ru",
            },
            {
              title: "Комитет по делам гражданского общества",
              url: "https://www.gov.kz/memleket/entities/akk?lang=ru",
            },
            {
              title: "Комитет по делам религий",
              url: "https://www.gov.kz/memleket/entities/din?lang=kk",
            },
          ],
        },
        {
          title: "Министерство культуры и спорта Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/mcs?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет по делам архивов и управления документацией",
              url: "https://www.gov.kz/memleket/entities/archive?lang=ru",
            },
            {
              title: "Комитет культуры",
              url: "https://www.gov.kz/memleket/entities/culture?lang=ru",
            },
            {
              title: "Комитет индустрии туризма",
              url: "https://www.gov.kz/memleket/entities/tourism?lang=ru",
            },
            {
              title: "Комитет по делам спорта и физической культуры",
              url: "https://www.gov.kz/memleket/entities/sport?lang=ru",
            },
          ],
        },
        {
          title: "Министерство национальной экономики Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/economy?lang=ru",
          showChildren: false,
          children: [
            {
              title:
                "Комитет по регулированию естественных монополий Министерства национальной экономики",
              url: "https://www.gov.kz/memleket/entities/krem?lang=ru",
            },
          ],
        },
        {
          title: "Министерство обороны Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/mod?lang=ru",
          showChildren: false,
          children: [],
        },
        {
          title: "Министерство образования и науки Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/edu?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет дошкольного и среднего образования",
              url: "https://www.gov.kz/memleket/entities/kdso?lang=ru",
            },
            {
              title:
                "Комитет языковой политики Министерство образования и науки",
              url: "https://www.gov.kz/memleket/entities/language?lang=ru",
            },
            {
              title:
                "Комитет по обеспечению качества в сфере образования и науки",
              url: "https://www.gov.kz/memleket/entities/control?lang=ru",
            },
            {
              title: "Комитет по охране прав детей",
              url: "https://www.gov.kz/memleket/entities/bala?lang=ru",
            },
            {
              title: "Комитет науки",
              url: "https://www.gov.kz/memleket/entities/sc?lang=ru",
            },
          ],
        },
        {
          title: "Министерство сельского хозяйства Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/moa?lang=ru",
          showChildren: false,
          children: [
            {
              title:
                "Комитет государственной инспекции в агропромышленном комплексе",
              url: "https://www.gov.kz/memleket/entities/agroindust?lang=ru",
            },
            {
              title: "Комитет ветеринарного контроля и надзора",
              url: "https://www.gov.kz/memleket/entities/vetcontrol?lang=ru",
            },
            {
              title: "Комитет по управлению земельными ресурсами",
              url: "https://www.gov.kz/memleket/entities/land?lang=ru",
            },
          ],
        },
        {
          title: "Министерство торговли и интеграции Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/mti?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет по защите прав потребителей  ",
              url: "https://www.gov.kz/memleket/entities/mti-kzpp?lang=ru",
            },
            {
              title: "Комитет технического регулирования и метрологии ",
              url: "https://www.gov.kz/memleket/entities/mti-ktrm?lang=ru",
            },
          ],
        },
        {
          title:
            "Министерство труда и социальной защиты населения Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/enbek?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет труда, социальной защиты и миграции",
              url: "https://www.gov.kz/memleket/entities/lspm?lang=ru",
            },
          ],
        },
        {
          title: "Министерство финансов Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/minfin?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет внутреннего государственного аудита",
              url: "https://www.gov.kz/memleket/entities/kvga?lang=ru",
            },
            {
              title: "Комитет казначейства",
              url: "https://www.gov.kz/memleket/entities/kazyna?lang=ru",
            },
            {
              title: "Комитет государственного имущества и приватизации",
              url: "https://www.gov.kz/memleket/entities/gosreestr?lang=ru",
            },
            {
              title: "Комитет государственных доходов",
              url: "https://www.gov.kz/memleket/entities/kgd?lang=ru",
            },
          ],
        },
        {
          title:
            "Министерство цифрового развития, инноваций и аэрокосмической промышленности Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/mdai?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет государственных услуг ",
              url: "https://www.gov.kz/memleket/entities/kgu?lang=ru",
            },
            {
              title: "Комитет геодезии и картографии ",
              url: "https://www.gov.kz/memleket/entities/kgk?lang=ru",
            },
            {
              title: "Комитет телекоммуникаций ",
              url: "https://www.gov.kz/memleket/entities/telecom?lang=ru",
            },
            {
              title: "Комитет по информационной безопасности ",
              url: "https://www.gov.kz/memleket/entities/infsecurity?lang=ru",
            },
            {
              title: "Аэрокосмический комитет ",
              url: "https://www.gov.kz/memleket/entities/kazcosmos?lang=ru",
            },
          ],
        },
        {
          title:
            "Министерство экологии, геологии и природных ресурсов Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/ecogeo?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет рыбного хозяйства  ",
              url: "https://www.gov.kz/memleket/entities/fishery?lang=ru",
            },
            {
              title: "Комитет лесного хозяйства и животного мира  ",
              url: "https://www.gov.kz/memleket/entities/forest?lang=ru",
            },
            {
              title: "Комитет экологического регулирования и контроля  ",
              url: "https://www.gov.kz/memleket/entities/cerc?lang=ru",
            },
            {
              title: "Комитет по водным ресурсам  ",
              url: "https://www.gov.kz/memleket/entities/water?lang=ru",
            },
            {
              title: "Комитет геологии  ",
              url: "https://www.gov.kz/memleket/entities/geology?lang=ru",
            },
          ],
        },
        {
          title: "Министерство энергетики Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/energo?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет атомного и энергетического надзора и контроля ",
              url: "https://www.gov.kz/memleket/entities/kaenk?lang=ru",
            },
          ],
        },
        {
          title: "Министерство юстиции Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/adilet?lang=ru",
          showChildren: false,
          children: [],
        },
        {
          title: "Министерство по чрезвычайным ситуациям Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/emer?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Комитет по гражданской обороне и воинским частям ",
              url: "https://www.gov.kz/memleket/entities/kgovch?lang=ru",
            },
            {
              title: "Комитет противопожарной службы ",
              url: "https://www.gov.kz/memleket/entities/kps?lang=ru",
            },
            {
              title: "Комитет промышленной безопасности ",
              url: "https://www.gov.kz/memleket/entities/kpb?lang=ru",
            },
            {
              title: "Комитет по государственным материальным резервам ",
              url: "https://www.gov.kz/memleket/entities/kgmr?lang=ru",
            },
          ],
        },
      ],
      akimats: [
        {
          title: "Акимат Акмолинской области",
          url: "https://www.gov.kz/memleket/entities/aqmola",
        },
        {
          title: "Акимат Актюбинской области",
          url: "https://www.gov.kz/memleket/entities/aktobe",
        },
        {
          title: "Акимат Алматинской области",
          url: "https://www.gov.kz/memleket/entities/zhetysu",
        },
        {
          title: "Акимат Атырауской области",
          url: "https://www.gov.kz/memleket/entities/atyrau",
        },
        {
          title: "Акимат Восточно-Казахстанской области",
          url: "https://www.gov.kz/memleket/entities/akimvko",
        },
        {
          title: "Акимат города Алматы",
          url: "https://www.gov.kz/memleket/entities/almaty",
        },
        {
          title: "Акимат города Нур-Султан",
          url: "https://www.gov.kz/memleket/entities/astana",
        },
        {
          title: "Акимат города Шымкент",
          url: "https://www.gov.kz/memleket/entities/shymkent",
        },
        {
          title: "Акимат Жамбылской области",
          url: "https://www.gov.kz/memleket/entities/zhambyl",
        },
        {
          title: "Акимат Западно-Казахстанской области",
          url: "https://www.gov.kz/memleket/entities/bko",
        },
        {
          title: "Акимат Карагандинской области",
          url: "https://www.gov.kz/memleket/entities/karaganda",
        },
        {
          title: "Акимат Костанайской области",
          url: "https://www.gov.kz/memleket/entities/kostanay",
        },
        {
          title: "Акимат Кызылординской области",
          url: "https://www.gov.kz/memleket/entities/kyzylorda",
        },
        {
          title: "Акимат Мангистауской области",
          url: "https://www.gov.kz/memleket/entities/mangystau",
        },
        {
          title: "Акимат Павлодарской области",
          url: "https://www.gov.kz/memleket/entities/pavlodar",
        },
        {
          title: "Акимат Северо-Казахстанской области",
          url: "https://www.gov.kz/memleket/entities/sko",
        },
        {
          title: "Акимат Туркестанской области",
          url: "https://www.gov.kz/memleket/entities/ontustik",
        },
      ],
      others: [
        {
          title:
            "Агентство по стратегическому планированию и реформам Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/aspr?lang=ru",
          showChildren: false,
          children: [
            {
              title: "Бюро национальной статистики",
              url: "https://stat.gov.kz/",
            },
          ],
        },
        {
          title:
            "Агентство Республики Казахстан по делам государственной службы",
          url: "https://www.gov.kz/memleket/entities/qyzmet/about/structure/188/1?lang=ru",
          showChildren: false,
        },
        {
          title: "Агентство Республики Казахстан по противодействию коррупции",
          url: "https://www.gov.kz/memleket/entities/anticorruption?lang=ru",
          showChildren: false,
        },
        {
          title:
            "Агентство по защите и развитию конкуренции Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/zk?lang=ru",
          showChildren: false,
        },
        {
          title:
            "Агентство Республики Казахстан по регулированию и развитию финансового рынка",
          url: "https://www.gov.kz/memleket/entities/ardfm?lang=ru",
          showChildren: false,
        },
        {
          title:
            "Счетный комитет по контролю за исполнением республиканского бюджета",
          url: "https://www.gov.kz/memleket/entities/esep?lang=ru",
          showChildren: false,
        },
        {
          title: "Служба государственной охраны Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/qrmkq?lang=ru",
          showChildren: false,
        },
        {
          title: "Агентство Республики Казахстан по финансовому мониторингу",
          url: "https://www.gov.kz/memleket/entities/afm?lang=ru",
          showChildren: false,
        },
        {
          title: "Высший Судебный Совет Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/vss?lang=ru",
          showChildren: false,
        },
        {
          title:
            "Пограничная служба Комитета национальной безопасности Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/shekaraknb?lang=ru",
          showChildren: false,
        },
        {
          title: "Уполномоченный по правам человека в Республике Казахстан",
          url: "https://www.gov.kz/memleket/entities/ombudsman?lang=ru",
          showChildren: false,
        },
        {
          title: "Антитеррористический центр Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/antiterrosticheskiy-centr?lang=ru",
          showChildren: false,
        },
        {
          title: "Комитет национальной безопасности Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/knb?lang=ru",
          showChildren: false,
        },
        {
          title: "Генеральная прокуратура Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/prokuror?lang=ru",
          showChildren: false,
          children: [
            {
              title:
                "Комитет по правовой статистике и специальным учетам Генеральной прокуратуры Республики Казахстан",
              url: "https://www.gov.kz/memleket/entities/pravstat?lang=ru",
            },
          ],
        },
        {
          title: "Центральная избирательная комиссия Республики Казахстан",
          url: "https://www.gov.kz/memleket/entities/election?lang=ru",
          showChildren: false,
        },
        {
          title: "Верховный суд Республики Казахстан",
          url: "https://sud.gov.kz/rus",
          showChildren: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.nav-xs{
  font-size: 16px;
}
</style>